import { authHeader, authHeaderBearer } from '../helper'

export const userService = {
  addUser,
  insertLog,
  insertOtherLog,
  insertForgotPasswordLog,
  checkForgotPassword,
  insertAdminUser,
  login,
  checkSession,
  getUserProfile,
  getUserDetail,
  updateUser,
  getPresignedURL,
  getUserList,
  getUserLogList,
  changeStatus,
  //getUserTotal,
  verify,
  updateUserProfile,
  getPresignedOwnerPutURL,
  updateAvatar,
  logout,
}

function addUser(token, user) {
  console.log('userService addAdminUser')
  return fetch(`${process.env.REACT_APP_API_URL}/admin/create`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify(user),
  }).then(handleResponse)
}
function insertLog(token, user_name, event_log) {
  console.log('userService insertLog')
  return fetch(`${process.env.REACT_APP_API_URL}/logs/create`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ userName: user_name, eventLog: event_log }),
  }).then(handleResponse)
}
function insertOtherLog(token, session_id, user_id, user_name, event_log) {
  console.log('userService insertOtherLog')
  return fetch(`${process.env.REACT_APP_API_URL}/logs/createOther`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      sessionId: session_id,
      userId: user_id,
      userName: user_name,
      eventLog: event_log,
    }),
  }).then(handleResponse)
}
function insertForgotPasswordLog(user_id, email) {
  console.log('userService insertForgotPasswordLog')
  return fetch(`${process.env.REACT_APP_API_URL}/logs/createForgotPassword`, {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      userId: user_id,
    }),
  }).then(handleResponse)
}
function checkForgotPassword(email) {
  console.log('userService checkForgotPassword')
  return fetch(`${process.env.REACT_APP_API_URL}/checkForgotPassword?email=${email}`, {
    method: 'GET',
  }).then(handleResponse)
}
function insertAdminUser(user) {
  console.log('userService insertAdminUser')
  return fetch(`${process.env.REACT_APP_API_URL}/admin/create`, {
    method: 'POST',
    //headers: noAuthHeader(),
    body: JSON.stringify(user),
  }).then(handleResponse)
}
function login(token) {
  console.log('userService login')
  return fetch(`${process.env.REACT_APP_API_URL}/login`, {
    method: 'POST',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
function checkSession(token, session_id) {
  console.log('userService checkSession')
  return fetch(`${process.env.REACT_APP_API_URL}/check_session`, {
    method: 'POST',
    headers: authHeader(token),
    body: JSON.stringify({ session_id: session_id }),
  }).then(handleResponse)
}
function getUserProfile(token, session_id) {
  console.log('userService getUserProfile')
  return fetch(`${process.env.REACT_APP_API_URL}/user_profile/${session_id}`, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
function updateUser(token, user_id, name, email, department, phone, session_id, creator_name) {
  console.log('userService updateUser')
  return fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      email: email,
      userId: user_id,
      name: name,
      department: department,
      phone: phone,
      sessionId: session_id,
      creatorName: creator_name,
    }),
  }).then(handleResponse)
}
function getUserDetail(token, user_id, session_id) {
  console.log('userService getUserDetail')
  return fetch(`${process.env.REACT_APP_API_URL}/users/${user_id}/${session_id}`, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
function getPresignedURL(token, img_filepath) {
  console.log('userService getPresignedURL')
  return fetch(`${process.env.REACT_APP_API_URL}/presignedGet`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ imgFilepath: img_filepath }),
  }).then(handleResponse)
}
function getUserList(token, session_id, current_page, keyword, sort_by, sort_order) {
  console.log('userService getUserList')
  const query_string =
    '?session_id=' +
    session_id +
    '&current_page=' +
    current_page +
    '&keyword=' +
    keyword +
    '&sort_by=' +
    sort_by +
    '&sort_order=' +
    sort_order
  return fetch(`${process.env.REACT_APP_API_URL}/users/list` + query_string, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
function getUserLogList(token, session_id, user_id, current_page, sort_by, sort_order) {
  console.log('userService getUserLogList')
  const query_string = `?session_id=${session_id}&current_page=${current_page}&user_id=${user_id}&sort_by=${sort_by}&sort_order=${sort_order}`
  return fetch(`${process.env.REACT_APP_API_URL}/usersLog/list` + query_string, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
/*function getUserTotal(token, session_id, current_page, keyword) {
  console.log('userService getUserTotal')
  const query_string =
    '?session_id=' + session_id + '&current_page=' + current_page + '&keyword=' + keyword
  return fetch(`${process.env.REACT_APP_API_URL}/users/count` + query_string, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}*/
function changeStatus(token, session_id, user_id, current_status, creator_name) {
  console.log('userService changeStatus')
  return fetch(`${process.env.REACT_APP_API_URL}/changeUserStatus`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      userId: user_id,
      currentStatus: current_status,
      sessionId: session_id,
      creatorName: creator_name,
    }),
  }).then(handleResponse)
}
function verify(user_id, verification_type) {
  console.log('userService verify')
  return fetch(`${process.env.REACT_APP_API_URL}/verify`, {
    method: 'POST',
    body: JSON.stringify({
      userId: user_id,
      verificationType: verification_type,
    }),
  }).then(handleResponse)
}
function updateUserProfile(token, session_id, name, email, department, phone) {
  console.log('userService updateUserProfile')
  return fetch(`${process.env.REACT_APP_API_URL}/admin/userProfile`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      session_id: session_id,
      name: name,
      email: email,
      department: department,
      phone: phone,
    }),
  }).then(handleResponse)
}

function getPresignedOwnerPutURL(token, session_id, user_id, filename) {
  console.log('userService getPresignedPutURL')
  return fetch(`${process.env.REACT_APP_API_URL}/presignedOwnerPut`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ session_id: session_id, filename: filename, user_id: user_id }),
  }).then(handleResponse)
}
function updateAvatar(token, session_id, user_id, filename, filepath) {
  console.log('userService updateAvatar')
  return fetch(`${process.env.REACT_APP_API_URL}/updateAvatar`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      session_id: session_id,
      filename: filename,
      filepath: filepath,
      user_id: user_id,
    }),
  }).then(handleResponse)
}
function logout(token, session_id) {
  console.log('userService logout')
  return fetch(`${process.env.REACT_APP_API_URL}/logout`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ session_id: session_id }),
  }).then(handleResponse)
}
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 401) {
        console.log('Error 401 Unauthorized Access!!!')
      }
      const error = (data && data.message) || response.statusText
      console.log(data)
      return Promise.reject(error)
    }
    return data
  })
}
