import { authHeaderBearer, noAuthHeader } from '../helper'
export const authService = {
  addUser,
  updateAdminEmail,
  login,
  logout,
  getPubkey,
  getVerifyData,
  verify,
  checkCaptcha,
  forgotPassword,
  resendActivation,
  resetPassword,
  changePassword,
  refreshToken,
  checkSession,
  getPrivilege,
  updateRoles,
}
function checkSession(user) {
  console.log('Service checkSession')
  //console.log('user > ', user);
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/check`, {
    method: 'POST',
    //headers: noAuthHeader(),
    body: JSON.stringify(user),
  }).then(handleResponse)
}
function getPubkey() {
  console.log('Service getPubkey')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/pubkey`, {
    method: 'GET',
  }).then(handleResponse)
}
function getVerifyData(verification_id) {
  console.log('Service getVerifyData')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/verify/${verification_id}`, {
    method: 'GET',
  }).then(handleResponse)
}
function verify(verification_id, verify_user_id, new_password) {
  console.log('Service verify')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/verify`, {
    method: 'POST',
    body: JSON.stringify({
      verification_id: verification_id,
      verify_user_id: verify_user_id,
      new_password: new_password,
    }),
  }).then(handleResponse)
}
function checkCaptcha(captchaToken) {
  console.log('Service checkCaptcha')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/checkCaptcha`, {
    method: 'POST',
    body: JSON.stringify({ captchaToken: captchaToken }),
  }).then(handleResponse)
}
function forgotPassword(email, captchaToken) {
  console.log('Service forgotPassword')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/forgotPassword`, {
    method: 'POST',
    body: JSON.stringify({ captchaToken: captchaToken, email: email }),
  }).then(handleResponse)
}
function resendActivation(token, session_id, verify_user_id, email, name) {
  console.log('Service resendActivation')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/resendActivation`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      sessionId: session_id,
      verifyUserId: verify_user_id,
      email: email,
      name: name,
    }),
  }).then(handleResponse)
}
function resetPassword(token, session_id, user_id, email, contact_name) {
  console.log('Service resetPassword')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/resetPassword`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({
      sessionId: session_id,
      userId: user_id,
      email: email,
      name: contact_name,
    }),
  }).then(handleResponse)
}
function refreshToken(refreshToken, session_id) {
  console.log('Service refreshToken')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/refreshToken`, {
    method: 'POST',
    headers: { refreshToken: refreshToken },
    body: JSON.stringify({ session_id: session_id }),
  }).then(handleResponse)
}
function addUser(token, email, name, roles, session_id) {
  console.log('authService addUser')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/create`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ email: email, roles: roles, name: name, session_id: session_id }),
  }).then(handleResponse)
}
function updateAdminEmail(token, session_id, email) {
  console.log('authService updateAdminEmail')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/email`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ session_id: session_id, email: email }),
  }).then(handleResponse)
}
function changePassword(token, session_id, new_password) {
  console.log('authService changePassword')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/password`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ session_id: session_id, password: new_password }),
  }).then(handleResponse)
}
function login(email, password) {
  console.log('authService login')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/admin/login`, {
    method: 'POST',
    body: JSON.stringify({ email: email, password: password }),
  }).then(handleResponse)
}
function logout(token, session_id) {
  console.log('authService logout')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {
    method: 'POST',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ session_id: session_id }),
  }).then(handleResponse)
}
function getPrivilege(token, user_id) {
  console.log('authService getPrivilege')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/privilege/${user_id}`, {
    method: 'GET',
    headers: authHeaderBearer(token),
  }).then(handleResponse)
}
function updateRoles(token, user_id, session_id, roles) {
  console.log('authService updateRoles')
  return fetch(`${process.env.REACT_APP_AUTH_URL}/auth/privilege`, {
    method: 'PUT',
    headers: authHeaderBearer(token),
    body: JSON.stringify({ sessionId: session_id, userId: user_id, roles: roles }),
  }).then(handleResponse)
}
/*function insertUser(token) {
    console.log('Service insertUser');
    return fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'POST',
      headers: authHeader(token),
    }).then(handleResponse);
  }*/
/*function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 400) {
        console.log('Error 400 Failed to fetch')
      }
      if (response.status === 401) {
        console.log('Error 401 Unauthorized Access!!!')
      }
      //console.log('data > ', data);
      //console.log('data.message > ', data.message);
      let error = response.statusText
      if (data && data.message) {
        error = data.message
      }
      if (data && data.error && data.error.message) {
        error = data.error.message
      }
      console.log('error > ', error)
      return Promise.reject(error)
    }
    return data
  })
}*/
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      if (response.status === 401) {
        console.log('Error 401 Unauthorized Access!!!')
      }
      const error = (data && data.message) || response.statusText
      console.log(data)
      return Promise.reject(error)
    }
    return data
  })
}
