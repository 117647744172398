import { userConstants } from '../constants'
export function userprofile(state = {}, action) {
  switch (action.type) {
    case userConstants.USER_PROFILE_CLEAR_ERROR:
      return {}
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return { update_success: true }
    case userConstants.UPDATE_PROFILE_FAIL:
      return {
        error: action.error,
        email_error: action.email_error,
      }
    default:
      return state
  }
}
