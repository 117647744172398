import { userService } from 'src/services'
import { utils } from '../helper'
import i18n from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import { authenticationConstants, uploadConstants } from 'src/constants'
import { authenticationActions } from '.'
export const minioActions = {
  //getPresignedKey,
  uploadFile,
  deleteAvatarFile,
  clearError,
  viewPublicFile,
}
function clearError() {
  console.log('minioActions.clearError')
  return (dispatch) => {
    dispatch({
      type: uploadConstants.UPLOAD_FILE_CLEAR_ERROR,
    })
  }
}
function viewPublicFile(token, filepath) {
  console.log('minioActions.viewPublicFile')
  return userService.getPresignedURL(token, filepath).then(
    (url) => {
      return url
    },
    (error) => {
      console.log('Error > ', error)
      return error
    },
  )
}
function deleteAvatarFile(token, refresh_token, session_id, user_id, userInfo) {
  console.log('minioActions.deleteAvatarFile')
  return (dispatch) => {
    dispatch(utils.blockui())
    userService.updateAvatar(token, session_id, user_id, '', '').then(
      (avatar) => {
        userInfo['avatar_url'] = ''
        userInfo['avatar_filename'] = ''
        userInfo['avatar_filepath'] = ''
        dispatch(success())
        dispatch(updateAvatar(userInfo))
        dispatch(utils.unblockui())
      },
      async (error) => {
        if (error.toString() === 'jwt expired') {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          console.error('upd_token > ', upd_token)
          if (upd_token && upd_token.error) {
            dispatch(failure(error.toString()))
            dispatch(utils.unblockui())
          } else {
            userService.updateAvatar(upd_token.token, session_id, user_id, '', '').then(
              (avatar) => {
                userInfo['avatar_url'] = ''
                userInfo['avatar_filename'] = ''
                userInfo['avatar_filepath'] = ''
                dispatch(success())
                dispatch(updateAvatar(userInfo))
                dispatch(utils.unblockui())
              },
              (e2) => {
                dispatch(failure(e2.toString()))
                dispatch(utils.unblockui())
              },
            )
          }
        } else {
          dispatch(failure(error.toString()))
          dispatch(utils.unblockui())
        }
      },
    )
  }
  function success() {
    return { type: uploadConstants.DELETE_AVATAR_FILE_SUCCESS }
  }
  function updateAvatar(userInfo) {
    return { type: authenticationConstants.UPDATE_USER_PROFILE_AVATAR, userInfo: userInfo }
  }
  function failure(error) {
    return { type: uploadConstants.DELETE_AVATAR_FILE_ERROR, error: error }
  }
}
function uploadFile(token, refresh_token, session_id, user_id, file, userInfo) {
  console.log('minioActions.uploadFile')
  const filesize = (file.size / 1024 / 1024).toFixed(4)
  //console.log('upload > ', file.name)
  //console.log('filesize > ', filesize)

  return (dispatch) => {
    // validate filesize.  return error when file exceed size.
    if (parseInt(process.env.REACT_APP_UPLOAD_PROFILE_IMG_SiZE) < parseInt(filesize)) {
      console.error('error file size exceed > ', filesize)
      dispatch(failure(i18n.t('image_too_large')))
      return
    }
    // validate file type. return error if filetype is not allow.
    const fileTypeArray = process.env.REACT_APP_UPLOAD_IMG_MIME.split(',')
    //console.log('fileTypeArray > ', fileTypeArray)
    const filetype = file.type
    //console.log('filetype > ', filetype)
    if (!fileTypeArray.includes(filetype)) {
      console.error('file type not allow > ', filetype)
      dispatch(failure(i18n.t('avatar_type_invalid')))
      return
    }
    dispatch(clearError())
    dispatch(utils.blockui())
    // Loading
    // Generate UUID File Name
    const fileFormat = file.name.substring(file.name.lastIndexOf('.'))
    //console.log('fileFormat > ', fileFormat)
    const uuidFileName = uuidv4() + fileFormat
    //console.log('uuidFileName > ', uuidFileName)
    // Profile is at folder profiles/user_id/uuid_file_name

    const filepath = 'profiles/admin/' + user_id + '/' + uuidFileName
    userService.getPresignedOwnerPutURL(token, session_id, user_id, filepath).then(
      (urlJson) => {
        fetch(urlJson.url, {
          method: 'PUT',
          body: file,
        })
          .then(() => {
            console.log('upload > ', file.name, ' > ', filepath)
            // Insert into DB
            // filename, UUID filename, user_id, update date
            // get PresignedGetURL for return photo
            userService.updateAvatar(token, session_id, user_id, file.name, filepath).then(
              (avatar) => {
                // update to userInfo. it will automatically display on top menu.
                //console.log('avatar > ', avatar)
                const avatar_url = avatar.data.update_users_by_pk.avatar_url
                const avatar_filename = avatar.data.update_users_by_pk.avatar_filename
                const avatar_filepath = avatar.data.update_users_by_pk.avatar_filepath
                userInfo['avatar_url'] = avatar_url
                userInfo['avatar_filename'] = avatar_filename
                userInfo['avatar_filepath'] = avatar_filepath
                dispatch(success())
                dispatch(updateAvatar(userInfo))
                dispatch(utils.unblockui())
              },
              (error) => {
                dispatch(failure(error.toString()))
                dispatch(utils.unblockui())
              },
            )
          })
          .catch((e) => {
            console.error(e)
            // display error
            dispatch(failure(e.toString()))
            dispatch(utils.unblockui())
          })
      },
      async (error) => {
        console.error('error > ', error)
        if (error.toString() === 'jwt expired') {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          console.error('upd_token > ', upd_token)
          if (upd_token && upd_token.error) {
            dispatch(failure(error.toString()))
            dispatch(utils.unblockui())
          } else {
            // refresh token success
            userService
              .getPresignedOwnerPutURL(upd_token.token, session_id, user_id, filepath)
              .then(
                (urlJson2) => {
                  fetch(urlJson2.url, {
                    method: 'PUT',
                    body: file,
                  })
                    .then(() => {
                      console.log('upload > ', file.name, ' > ', filepath)
                      // Insert into DB
                      // filename, UUID filename, user_id, update date
                      // get PresignedGetURL for return photo
                      userService
                        .updateAvatar(upd_token.token, session_id, user_id, file.name, filepath)
                        .then(
                          (avatar) => {
                            // update to userInfo. it will automatically display on top menu.
                            //console.log('avatar > ', avatar)
                            const avatar_url = avatar.data.update_users_by_pk.avatar_url
                            const avatar_filename = avatar.data.update_users_by_pk.avatar_filename
                            const avatar_filepath = avatar.data.update_users_by_pk.avatar_filepath
                            userInfo['avatar_url'] = avatar_url
                            userInfo['avatar_filename'] = avatar_filename
                            userInfo['avatar_filepath'] = avatar_filepath
                            dispatch(success())
                            dispatch(updateAvatar(userInfo))
                            dispatch(utils.unblockui())
                          },
                          (e) => {
                            dispatch(failure(e.toString()))
                            dispatch(utils.unblockui())
                          },
                        )
                    })
                    .catch((e1) => {
                      console.error(e1)
                      // display error
                      dispatch(failure(e1.toString()))
                      dispatch(utils.unblockui())
                    })
                },
                (e2) => {
                  dispatch(failure(e2.toString()))
                  dispatch(utils.unblockui())
                },
              )
          }
        }
      },
    )
  }
  function success() {
    return { type: uploadConstants.UPLOAD_FILE_SUCCESS }
  }
  function updateAvatar(userInfo) {
    return { type: authenticationConstants.UPDATE_USER_PROFILE_AVATAR, userInfo: userInfo }
  }
  function failure(error) {
    return { type: uploadConstants.UPLOAD_FILE_ERROR, error: error }
  }
}
