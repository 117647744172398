export const authenticationConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',

  REGISTER_CLEAR: 'REGISTER_CLEAR',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  GET_VERIFY_SUCCESS: 'GET_VERIFY_SUCCESS',
  GET_VERIFY_FAIL: 'GET_VERIFY_FAIL',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  VERIFY_FAIL: 'VERIFY_FAIL',

  CHECK_REQUEST: 'CHECK_REQUEST',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAIL: 'CHECK_FAIL',

  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAIL: 'REFRESH_TOKEN_FAIL',

  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',

  UPDATE_USER_PROFILE_AVATAR: 'UPDATE_USER_PROFILE_AVATAR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT: 'USERS_LOGOUT',
}
