export const menuActions = {
  toggle,
  toggleLang,
}

function toggle(sidebarShow) {
  return (dispatch) => {
    dispatch({
      type: 'set',
      sidebarShow: sidebarShow,
    })
  }
}
function toggleLang(lng) {
  return (dispatch) => {
    dispatch({
      type: 'lng',
      lng: lng,
    })
  }
}
