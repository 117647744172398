import { authenticationConstants, userConstants } from '../constants'

let user = {}
const initialState = user ? { user: '', loading: false, error: '', userInfo: '' } : {}

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authenticationConstants.LOGIN_REQUEST:
      return {
        loading: true,
      }
    case authenticationConstants.LOGIN_SUCCESS:
      return {
        user: action.user,
        userInfo: action.userInfo,
      }
    case authenticationConstants.UPDATE_USER_PROFILE_AVATAR:
      const user = state.user
      return {
        user: user,
        userInfo: action.userInfo,
      }
    case userConstants.UPDATE_PROFILE_SUCCESS:
      return {
        user: state.user,
        userInfo: action.userInfo,
      }

    case authenticationConstants.LOGIN_FAIL:
      return { loading: false, error: action.error }
    case authenticationConstants.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        error: '',
      }

    case authenticationConstants.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: action.user,
        error: '',
      }
    case authenticationConstants.REFRESH_TOKEN_FAIL:
      return { error: action.error }

    case authenticationConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
      }
    case authenticationConstants.CHANGE_PASSWORD_FAIL:
      return { ...state, minorError: action.error }
    case authenticationConstants.CHECK_SUCCESS:
      return {
        ...state,
        error: '',
      }
    case authenticationConstants.CHECK_FAIL:
      return { error: action.error }
    case authenticationConstants.CLEAR_ERROR:
      return {
        ...state,
        error: '',
        minorError: '',
        closePasswordModal: false,
        changePasswordSuccess: false,
      }
    case authenticationConstants.GET_VERIFY_SUCCESS:
      return {
        error: '',
        verifyUserId: action.verifyUserId,
        verificationType: action.verificationType,
      }
    case authenticationConstants.GET_VERIFY_FAIL:
      return { error: action.error }
    case authenticationConstants.VERIFY_SUCCESS:
      return {
        error: '',
        verifyUserId: '',
        verificationType: action.verificationType,
        verifySuccess: true,
      }
    case authenticationConstants.VERIFY_FAIL:
      return { error: action.error }
    case authenticationConstants.LOGOUT:
      return {
        minorError: '',
        error: '',
        user: '',
        userInfo: '',
      }
    default:
      return state
  }
}
