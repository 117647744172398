import { authenticationConstants } from '../constants'
export function registration(state = {}, action) {
  switch (action.type) {
    case authenticationConstants.REGISTER_CLEAR:
      return {
        loading: false,
        registerd: false,
      }
    case authenticationConstants.REGISTER_REQUEST:
      return {
        loading: true,
      }
    case authenticationConstants.REGISTER_SUCCESS:
      return {
        loading: false,
        registerd: true,
      }
    case authenticationConstants.REGISTER_FAIL:
      return { loading: false, error: action.error, registerd: false }

    default:
      return state
  }
}
