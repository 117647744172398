import i18n from 'i18next'
import res_en from './locales/en/translations.json'
import res_th from './locales/th/translations.json'

export const resources = {
  en: res_en,
  th: res_th,
}
i18n.init({
  resources,
  lng: 'en',
  fallbackLng: ['en', 'th'],
})
