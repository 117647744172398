import { combineReducers } from 'redux'
import { authentication } from './authentication.reducer'
import { registration } from './registration.reducer'
import { menu } from './menu.reducer'
import { blockui } from './blockui.reducer'
import { alert } from './alert.reducer'
import { upload } from './upload.reducer'
import { userprofile } from './userprofile.reducer'
import { users } from './users.reducer'
const rootReducer = combineReducers({
  authentication,
  registration,
  menu,
  blockui,
  alert,
  upload,
  userprofile,
  users,
})

export default rootReducer
