import { blockuiConstants } from '../constants'
export function blockui(state = {}, action) {
  switch (action.type) {
    case blockuiConstants.BLOCKUI_LOADING:
      return {
        loading: true,
      }
    case blockuiConstants.BLOCKUI_DONE:
      return {
        loading: false,
      }
    default:
      return state
  }
}
