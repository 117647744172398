import { userConstants } from '../constants'
export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.USER_LIST_CLEAR_ERROR:
      return { ...state }
    case userConstants.USER_LIST_SUCCESS:
      return { userList: action.userList, userTotal: action.userTotal }
    case userConstants.USER_LIST_FAIL:
      return {
        error: action.error,
      }
    case userConstants.USERLOG_LIST_SUCCESS:
      return {
        ...state,
        userLogList: action.userLogList,
        userLogTotal: action.userLogTotal,
        userLogError: '',
      }
    case userConstants.USERLOG_LIST_FAIL:
      return {
        ...state,
        userLogError: action.error,
      }
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        add_user_complete: true,
        add_user_error: '',
        add_user_email_error: false,
      }
    case userConstants.ADD_USER_FAIL:
      return {
        ...state,
        add_user_error: action.add_user_error,
        add_user_email_error: action.add_user_email_error,
      }
    case userConstants.ADD_USER_CLEAR_ERROR:
      return {
        ...state,
        add_user_complete: false,
        add_user_error: '',
        add_user_email_error: false,
        resend_email_complete: false,
        change_status_complete: false,
        change_status_error: '',
      }
    case userConstants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserComplete: true,
        updateUserError: '',
        updateUserEmailError: false,
      }
    case userConstants.UPDATE_USER_FAIL:
      return {
        ...state,
        updateUserError: action.updateUserError,
        updateUserEmailError: action.updateUserEmailError,
      }
    /*case userConstants.UPDATE_USER_CLEAR_ERROR:
      return {
        ...state,
        add_user_complete: false,
        add_user_error: '',
        add_user_email_error: false,
        resend_email_complete: false,
        change_status_complete: false,
        change_status_error: '',
        updateUserComplete: false,
        updateUserError: '',
        updateUserEmailError: false,
      }*/
    case userConstants.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        userDetail: action.userDetail,
        get_user_detail_error: '',
      }
    case userConstants.GET_USER_DETAIL_FAIL:
      return {
        ...state,
        get_user_detail_error: action.error,
      }
    case userConstants.GET_USER_DETAIL_CLEAR_ERROR:
      return {
        ...state,
        get_user_detail_error: '',
        updateUserComplete: false,
        updateUserError: '',
        updateUserEmailError: false,
      }
    case userConstants.USER_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        change_status_success: true,
        resend_email_complete: false,
        change_status_error: '',
      }
    case userConstants.USER_CHANGE_STATUS_FAIL:
      return {
        ...state,
        change_status_success: false,
        resend_email_complete: false,
        change_status_error: action.error,
      }
    case userConstants.RESEND_ACTIVATION_EMAIL_SUCCESS:
      return {
        ...state,
        change_status_success: true,
        resend_email_complete: true,
        change_status_error: '',
      }

    default:
      return state
  }
}
