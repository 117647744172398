import { authenticationConstants } from '../constants'
import { authService, userService } from '../services'
import { menuActions } from './menu.actions'
import { utils, history } from '../helper'
import i18n from 'i18next'
import jwt from 'jsonwebtoken'
//import jwt from 'jsonwebtoken'
//import { signInWithEmailAndPassword, signout } from '../authentication'
export const authenticationActions = {
  clearRegister,
  clearError,
  clearAlert,
  getVerifyData,
  verify,
  forgotPassword,
  register,
  login,
  logout,
  checkToken,
  refreshToken,
  eject,
  changePassword,
}

function clearRegister() {
  console.log('authenticationActions.clearRegister')
  return (dispatch) => {
    dispatch({
      type: authenticationConstants.REGISTER_CLEAR,
    })
  }
}
function clearError() {
  console.log('authenticationActions.clearError')
  return (dispatch) => {
    dispatch({
      type: authenticationConstants.CLEAR_ERROR,
    })
    dispatch(utils.unblockui())
  }
}
function clearAlert() {
  console.log('authenticationActions.clearAlert')
  return (dispatch) => {
    dispatch(utils.clearAlert())
  }
}
function getVerifyData(verification_id) {
  console.log('authenticationActions.getVerifyData > ', verification_id)
  return (dispatch) => {
    dispatch(utils.blockui())

    authService.getVerifyData(verification_id).then(
      (user) => {
        console.log('user > ', user)
        if (user.data.authentication_valid_email_verification.length < 1) {
          dispatch(failure(i18n.t('verification_id_not_found')))
          dispatch(utils.unblockui())
        } else {
          const user_id = user.data.authentication_valid_email_verification[0].user_id
          const verification_type =
            user.data.authentication_valid_email_verification[0].verification_type

          dispatch(success(user_id, verification_type))
          dispatch(utils.unblockui())
        }
      },
      (error) => {
        console.log('error > ', error)
        dispatch(failure(error.toString()))
        dispatch(utils.unblockui())
      },
    )
  }
  function success(verify_user_id, verification_type) {
    return {
      type: authenticationConstants.GET_VERIFY_SUCCESS,
      verifyUserId: verify_user_id,
      verificationType: verification_type,
    }
  }
  function failure(error) {
    return { type: authenticationConstants.GET_VERIFY_FAIL, error: error }
  }
}
function verify(verification_id, verify_user_id, new_password) {
  console.log('authenticationActions.verify')

  return (dispatch) => {
    dispatch(utils.blockui())
    authService.verify(verification_id, verify_user_id, new_password).then(
      (userAuth) => {
        console.log('userAuth > ', userAuth)
        const verificationType =
          userAuth.data.delete_authentication_email_verification_by_pk.verification_type
        userService.verify(verify_user_id, verificationType).then(
          (user) => {
            console.log('user > ', user)

            dispatch(success(verificationType))
            dispatch(utils.unblockui())
          },
          (e1) => {
            console.log('e1 > ', e1)
            dispatch(failure(e1.toString()))
            dispatch(utils.unblockui())
          },
        )
      },
      (error) => {
        console.log('error > ', error)
        dispatch(failure(error.toString()))
        dispatch(utils.unblockui())
      },
    )
  }
  function success(verificationType) {
    return { type: authenticationConstants.VERIFY_SUCCESS, verificationType: verificationType }
  }
  function failure(error) {
    return { type: authenticationConstants.VERIFY_FAIL, error: error }
  }
}
function forgotPassword(email, captchaToken) {
  console.log('authenticationActions.forgotPassword')
  return async (dispatch) => {
    dispatch(utils.blockui())
    try {
      //check email and status(block user can not forgot password.)
      const user = await userService.checkForgotPassword(email)
      console.log('user > ', user)
      const auth = await authService.forgotPassword(email, captchaToken)
      console.log('auth > ', auth)
      if (auth.success) {
        // log to db
        const user_id = auth.data.insert_authentication_email_verification_one.user_id
        const userLog = await userService.insertForgotPasswordLog(user_id, email)
        console.log('userLog > ', userLog)
        dispatch(success())
        dispatch(utils.unblockui())
      } else {
        // robot
        dispatch(failure(i18n.t('fail_captcha')))
        dispatch(utils.unblockui())
      }
    } catch (e1) {
      console.log('e1 > ', e1)
      console.log('e1.toString() > ', e1.toString())
      if (e1.toString() === 'Not Found') {
        dispatch(failure(i18n.t('email_not_found')))
        dispatch(utils.unblockui())
      } else if (e1.toString() === 'User is blocked.') {
        dispatch(failure(i18n.t('user_is_block')))
        dispatch(utils.unblockui())
      } else {
        dispatch(failure(e1.toString()))
        dispatch(utils.unblockui())
      }
    }
  }
  function success() {
    return { type: authenticationConstants.VERIFY_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.VERIFY_FAIL, error: error }
  }
}
function register(name, email, password) {
  console.log('authenticationActions.register > ', email)
  return (dispatch) => {
    dispatch(request())
    authService.insertAdminUser(email, password).then(
      (user) => {
        console.log('success insert auth user > ', user)
        // Insert into User DB, after auth insert successfully.
        const user_insert = {
          email: email,
          name: name,
          id: user.data.insert_authentication_users.returning[0].user_id,
          auth_provider: 'local',
          roles: {
            user_mgt: true,
            buyer_mgt: true,
            supplier_mgt: true,
            company_mgt: true,
          },
        }
        userService.insertAdminUser(user_insert, success, failure).then(
          (user_admin) => {
            console.log('return insert user_admin > ', user_admin)
            dispatch(success())
          },
          (error) => {
            console.log('error > ', error)
            dispatch(failure(error.toString()))
          },
        )
      },
      (error) => {
        console.log('error > ', error)
        dispatch(failure(error.toString()))
      },
    )
  }
  function request() {
    return { type: authenticationConstants.REGISTER_REQUEST }
  }
  function success() {
    return { type: authenticationConstants.REGISTER_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.REGISTER_FAIL, error: error }
  }
}

function login(email, password) {
  console.log('authenticationActions.login > ', email)
  return (dispatch) => {
    dispatch(utils.blockui())

    authService.login(email, password).then(
      (login_result) => {
        console.log('login_result > ', login_result)
        //console.log('login_result.token > ', login_result.token)
        //console.log('login_result.user_id > ', login_result.user_id)
        if (login_result.user_id) {
          //Success
          userService.login(login_result.token).then(
            (userInfo) => {
              console.log('userInfo > ', userInfo)
              dispatch(success(login_result, userInfo.data.users_by_pk))
              dispatch(utils.unblockui())
              dispatch(menuActions.toggle(true))
            },
            (errorUserService) => {
              console.log('errorUserService > ', errorUserService)
              if (errorUserService.toString() === 'TypeError: Failed to fetch') {
                dispatch(failure(i18n.t('connection_error')))
              } else {
                dispatch(failure(errorUserService.toString()))
              }
              dispatch(utils.unblockui())
            },
          )
        } else {
          //Fail
          if (login_result.code) {
            if (login_result.code === 'auth/user-not-found') {
              dispatch(failure(login_result.message))
            } else if (login_result.code === 'auth/wrong-password') {
              dispatch(failure(login_result.message))
            } else {
              dispatch(failure(login_result.message))
            }
          } else {
            dispatch(failure(i18n.t('e500')))
          }
        }
      },
      (error) => {
        console.log('error > ', error)
        if (error.toString() === 'TypeError: Failed to fetch') {
          dispatch(failure(i18n.t('connection_error')))
        } else {
          dispatch(failure(error.toString()))
        }
        dispatch(utils.unblockui())
      },
    )
  }
  function success(user, userInfo) {
    return { type: authenticationConstants.LOGIN_SUCCESS, user: user, userInfo: userInfo }
  }
  function failure(error) {
    return { type: authenticationConstants.LOGIN_FAIL, error: error }
  }
}
function logout(token, session_id, user_name) {
  console.log('authenticationActions.logout')
  return (dispatch) => {
    dispatch(utils.blockui())
    //authService.logout(token, session_id).then(
    //  (auth) => {
    userService.insertLog(token, user_name, 'Logout')
    userService.logout(token, session_id).then(
      (user) => {
        console.log('success logout user > ', user)

        dispatch(success())

        dispatch(utils.showAlert(i18n.t('logout_complete'), 'success'))
        dispatch(utils.unblockui())
      },
      (error) => {
        console.log('error > ', error)
        dispatch(success())
        dispatch(utils.unblockui())
      },
    )
  }
  function success() {
    return { type: authenticationConstants.LOGOUT }
  }
}
/*
function checkSession(token, session_id) {
  console.log('authenticationActions.checkSession')
  console.log('session_id > ', session_id)
  return (dispatch) => {
    dispatch(utils.blockui())
    userService.checkSession(token, session_id).then(
      (user) => {
        console.log('success checkSession user > ', user)
        dispatch(success())
        dispatch(utils.unblockui())
      },
      (error) => {
        console.log('error > ', error.toString())
        if (error === 'Unauthorized' || error.toString === 'Unauthorized') {
          dispatch(failure(i18n.t('session_timeout')))
          //dispatch(utils.showAlert(i18n.t('session_timeout'), 'danger'))
          history.push('/')
        } else {
          dispatch(failure(error.toString()))
          //dispatch(utils.showAlert(error.toString(), 'danger'))
          history.push('/')
        }
        dispatch(utils.unblockui())
      },
    )
    // check session timeout
  }
  function success() {
    return { type: authenticationConstants.CHECK_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.CHECK_FAIL, error: error }
  }
}
*/
function checkToken(token, refreshToken, session_id) {
  console.log('authenticationActions.checkToken')

  return (dispatch) => {
    dispatch(utils.blockui())
    authService
      .getPubkey()
      .then(
        (pubkey) => {
          return pubkey
        },
        (error) => {
          console.log('error > ', error.toString())
          if (error.toString() === 'TypeError: Failed to fetch') {
            dispatch(failure(i18n.t('connection_error')))
          } else {
            dispatch(failure(error.toString()))
          }
          dispatch(utils.unblockui())
        },
      )
      .then((pubkey) => {
        //console.log('pubkey > ', pubkey)
        if (pubkey !== undefined) {
          try {
            const cert = pubkey.PUBLIC_KEY
            const decoded = jwt.verify(token, cert)
            console.log('Token Passed > ', decoded)
            dispatch(utils.unblockui())
            dispatch(success())
          } catch (e) {
            console.log('e > ', e)
            if (e.name === 'TokenExpiredError') {
              //refresh token
              console.log('refreshToken > ', refreshToken)
              dispatch(refreshTokenIternal(refreshToken, session_id))
            } else {
              dispatch(failure(e))
              history.push('/')
              dispatch(utils.unblockui())
            }
          }
        }
      })

    // check session timeout
  }
  function success() {
    return { type: authenticationConstants.CHECK_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.CHECK_FAIL, error: error }
  }
}
function refreshTokenIternal(refreshToken, session_id) {
  console.log('authenticationActions.refreshToken')
  return (dispatch) => {
    dispatch(utils.blockui())
    authService.refreshToken(refreshToken, session_id).then(
      (user) => {
        console.log('success refreshToken user > ', user)
        dispatch(success(user))
        dispatch(utils.unblockui())
      },
      (error) => {
        console.log('error > ', error.toString())
        if (error === 'jwt expired') {
          dispatch(failure(i18n.t('jwt_expired')))
        } else if (error.toString() === 'TypeError: Failed to fetch') {
          dispatch(failure(i18n.t('connection_error')))
        } else {
          dispatch(failure(error.toString()))
        }
        dispatch(utils.unblockui())
      },
    )
  }
  function success(user) {
    return { type: authenticationConstants.REFRESH_TOKEN_SUCCESS, user: user }
  }
  function failure(error) {
    return {
      type: authenticationConstants.REFRESH_TOKEN_FAIL,
      error: error,
    }
  }
}
function eject() {
  console.log('authenticationActions.eject')
  return (dispatch) => {
    dispatch(utils.blockui())
    dispatch(success())
    dispatch(utils.unblockui())
  }
  function success() {
    return { type: authenticationConstants.LOGOUT }
  }
}
function changePassword(token, refresh_token, session_id, new_password, user_name) {
  console.log('authenticationActions.changePassword')
  return (dispatch) => {
    dispatch(utils.blockui())
    authService.changePassword(token, session_id, new_password).then(
      (user) => {
        //console.log('user > ', user)
        userService.insertLog(token, user_name, 'Change Password')
        dispatch(success())
        //dispatch(utils.showAlert(i18n.t('change_password_success'), 'success'))
        dispatch(utils.unblockui())
      },
      async (error) => {
        if (error.toString() === 'jwt expired') {
          const upd_token = await refreshToken(dispatch, refresh_token, session_id)
          if (upd_token && upd_token.error) {
            dispatch(failure(error.toString()))
            dispatch(utils.unblockui())
          } else {
            authService.changePassword(upd_token.token, session_id, new_password).then(
              (user) => {
                dispatch(success())
                //dispatch(utils.showAlert(i18n.t('change_password_success'), 'success'))
                dispatch(utils.unblockui())
              },
              (error) => {
                dispatch(failure(error.toString()))
                dispatch(utils.unblockui())
              },
            )
          }
        }
      },
    )
  }
  function success() {
    return { type: authenticationConstants.CHANGE_PASSWORD_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.CHANGE_PASSWORD_FAIL, error: error }
  }
}
async function refreshToken(dispatch, refreshToken, session_id) {
  try {
    const upd_token = await authService.refreshToken(refreshToken, session_id)
    console.log('upd_token > ', upd_token)
    dispatch(success(upd_token))
    return upd_token
  } catch (e) {
    console.log('refreshToken error > ', e)
    dispatch(failure(e.toString()))
    dispatch(utils.unblockui())
    return { error: e }
  }

  function success(user) {
    return { type: authenticationConstants.REFRESH_TOKEN_SUCCESS, user: user }
  }
  function failure(error) {
    return {
      type: authenticationConstants.REFRESH_TOKEN_FAIL,
      error: error,
    }
  }
}
