import React from 'react'
import { utils } from 'src/helper'
import { authService, userService } from 'src/services'
import { authenticationActions } from '.'
import { userConstants } from '../constants'
import i18n from 'i18next'

export const userActions = {
  clearUserProfileFailAlert,
  clearAddUserError,
  clearUserDetailError,
  updateProfile,
  addUser,
  updateUser,
  userList,
  getUserLogs,
  getUserDetail,
  changeStatus,
  resetPassword,
}
function clearUserProfileFailAlert() {
  console.log('userActions.clearUserProfileFailAlert')
  return (dispatch) => {
    dispatch({
      type: userConstants.USER_PROFILE_CLEAR_ERROR,
    })
  }
}
function clearAddUserError() {
  console.log('userActions.clearAddUserError')
  return (dispatch) => {
    dispatch({
      type: userConstants.ADD_USER_CLEAR_ERROR,
    })
  }
}
function clearUserDetailError() {
  console.log('userActions.clearUserDetailError')
  return (dispatch) => {
    dispatch({
      type: userConstants.GET_USER_DETAIL_CLEAR_ERROR,
    })
  }
}
function addUser(
  token,
  refresh_token,
  session_id,
  name,
  email,
  department,
  phone,
  roles,
  creator_name,
  searchParam,
) {
  console.log('userActions.addUser')
  console.log('searchParam > ', searchParam)
  return async (dispatch) => {
    dispatch(utils.blockui())

    authService.addUser(token, email, name, roles, session_id).then(
      (user_auth) => {
        console.log('user_auth > ', user_auth)
        try {
          const user_id = user_auth.data.insert_authentication_users.returning[0].user_id
          console.log('user_id > ', user_id)
          // insert User
          const add_user = {
            user_id: user_id,
            creator_name: creator_name,
            session_id: session_id,
            name: name,
            email: email,
            department: department,
            phone: phone,
          }
          userService.addUser(token, add_user).then(
            (user) => {
              console.log('user > ', user)

              // reload userList * pass in search param
              // close modal
              // alert success

              /*dispatch(
                userList(
                  token,
                  refresh_token,
                  session_id,
                  searchParam.currentPage,
                  searchParam.keyword,
                  searchParam.sortBy,
                  searchParam.sortOrder,
                ),
              )*/
              dispatch(success())
              dispatch(utils.unblockui())
              //dispatch(utils.showAlert(i18n.t('add_user_success'), 'success'))
            },
            (e3) => {
              console.log('e3 > ', e3)
              dispatch(failure(e3.toString()))
              dispatch(utils.unblockui())
            },
          )
        } catch (e2) {
          console.log('e2 > ', e2)
          dispatch(failure(e2.toString()))
          dispatch(utils.unblockui())
        }
      },
      async (e1) => {
        console.log('e1 > ', e1)
        if (e1.toString() === 'jwt expired') {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          if (upd_token && upd_token.error) {
            dispatch(failure(upd_token.error))
            dispatch(utils.unblockui())
            return
          }
          authService.addUser(upd_token.token, email, name, roles, session_id).then((user_auth) => {
            console.log('user_auth > ', user_auth)
            try {
              const user_id = user_auth.data.insert_authentication_users.returning[0].user_id
              console.log('user_id > ', user_id)
              // insert User
              const add_user = {
                user_id: user_id,
                creator_name: creator_name,
                session_id: session_id,
                name: name,
                email: email,
                department: department,
                phone: phone,
              }
              userService.addUser(upd_token.token, add_user).then(
                (user) => {
                  console.log('user > ', user)

                  // reload userList * pass in search param
                  // close modal
                  // alert success

                  /*dispatch(
                    userList(
                      upd_token.token,
                      upd_token.refresh_token,
                      session_id,
                      searchParam.currentPage,
                      searchParam.keyword,
                      searchParam.sortBy,
                      searchParam.sortOrder,
                    ),
                  )*/
                  dispatch(success())
                  dispatch(utils.unblockui())
                  //dispatch(utils.showAlert(i18n.t('add_user_success'), 'success'))
                },
                (e3) => {
                  console.log('e3 > ', e3)
                  dispatch(failure(e3.toString()))
                  dispatch(utils.unblockui())
                },
              )
            } catch (e2) {
              console.log('e2 > ', e2)
              dispatch(failure(e2.toString()))
              dispatch(utils.unblockui())
            }
          })
        } else if (e1.toString() === 'Error Duplicate Email.') {
          dispatch(failure(i18n.t('duplicate_email'), true))
          dispatch(utils.unblockui())
        } else {
          dispatch(failure(e1.toString()), false)
          dispatch(utils.unblockui())
        }
      },
    )
  }
  function success() {
    return { type: userConstants.ADD_USER_SUCCESS }
  }
  function failure(add_user_error, add_user_email_error) {
    return {
      type: userConstants.ADD_USER_FAIL,
      add_user_error: add_user_error,
      add_user_email_error: add_user_email_error,
    }
  }
}
function updateUser(
  token,
  refresh_token,
  session_id,
  user_id,
  name,
  email,
  department,
  phone,
  roles,
  creator_name,
) {
  console.log('userActions.updateUser')
  return async (dispatch) => {
    try {
      dispatch(utils.blockui())
      const updateUser = await userService.updateUser(
        token,
        user_id,
        name,
        email,
        department,
        phone,
        session_id,
        creator_name,
      )
      console.log('updateUser > ', updateUser)
      // update roles on authService
      const updateUserRolse = await authService.updateRoles(token, user_id, session_id, roles)
      console.log('updateUserRolse > ', updateUserRolse)
      dispatch(success())
      dispatch(utils.unblockui())
    } catch (e) {
      console.log('editUser error > ', e)
      if (e.toString() === 'jwt expired') {
        // redo after refresh token
        const upd_token = await authenticationActions.refreshToken(
          dispatch,
          refresh_token,
          session_id,
        )
        if (upd_token && upd_token.error) {
          dispatch(failure(upd_token.error))
          dispatch(utils.unblockui())
        } else {
          const updateUser = await userService.updateUser(
            upd_token.token,
            user_id,
            name,
            email,
            department,
            phone,
            session_id,
            creator_name,
          )
          console.log('updateUser > ', updateUser)
          // update roles on authService
          const updateUserRolse = await authService.updateRoles(
            upd_token.token,
            user_id,
            session_id,
            roles,
          )
          console.log('updateUserRolse > ', updateUserRolse)
          dispatch(success())
          dispatch(utils.unblockui())
        }
      } else {
        if (e.toString() === 'Page Not Found.') {
          dispatch(failure(i18n.t('user_not_found')))
          dispatch(utils.unblockui())
        } else {
          dispatch(failure(e.toString()))
          dispatch(utils.unblockui())
        }
      }
    }
  }
  function success() {
    return { type: userConstants.UPDATE_USER_SUCCESS }
  }
  function failure(updateUserError, updateUserEmailError) {
    return {
      type: userConstants.UPDATE_USER_FAIL,
      updateUserError: updateUserError,
      updateUserEmailError: updateUserEmailError,
    }
  }
}
function updateProfile(user, session_id, name, email, department, phone, userInfo) {
  console.log('userActions.updateProfile')
  return async (dispatch) => {
    dispatch(utils.blockui())
    const current_email = userInfo.email

    if (current_email !== email) {
      console.log('Email update')
      const upd_profile_auth = await updateProfileAuth(
        dispatch,
        user.token,
        user.refreshToken,
        session_id,
        email,
      )
      console.log('upd_profile_auth > ', upd_profile_auth)
      if (upd_profile_auth && upd_profile_auth.error) {
        if (upd_profile_auth.error === 'duplicate email') {
          dispatch(failure(i18n.t('duplicate_email'), 'duplicate_email'))
        } else {
          dispatch(failure(upd_profile_auth.error))
        }
        dispatch(utils.unblockui())
        return
      }
      const upd_profile_api = await updateProfileApi(
        dispatch,
        upd_profile_auth.token,
        upd_profile_auth.refreshToken,
        session_id,
        name,
        email,
        department,
        phone,
      )
      if (upd_profile_api && upd_profile_api.error) {
        dispatch(failure(upd_profile_api.error))
        dispatch(utils.unblockui())
        return
      }
      console.log('upd_profile_api > ', upd_profile_api)
      userInfo['name'] = name
      userInfo['email'] = email
      userInfo['department'] = department
      userInfo['phone'] = phone
      dispatch(success(userInfo))
      dispatch(utils.unblockui())
      //dispatch(utils.showAlert(i18n.t('update_profile_success'), 'success'))
    } else {
      const upd_profile_api = await updateProfileApi(
        dispatch,
        user.token,
        user.refreshToken,
        session_id,
        name,
        email,
        department,
        phone,
      )
      console.log('upd_profile_api > ', upd_profile_api)
      if (upd_profile_api && upd_profile_api.error) {
        dispatch(failure(upd_profile_api.error))
        dispatch(utils.unblockui())
        return
      }
      console.log('upd_profile_api > ', upd_profile_api)
      userInfo['name'] = name
      userInfo['email'] = email
      userInfo['department'] = department
      userInfo['phone'] = phone
      dispatch(success(userInfo))
      dispatch(utils.unblockui())
      //dispatch(utils.showAlert(i18n.t('update_profile_success'), 'success'))
    }
  }
  function success(userInfo) {
    return { type: userConstants.UPDATE_PROFILE_SUCCESS, userInfo: userInfo }
  }
  function failure(error, email_error) {
    return { type: userConstants.UPDATE_PROFILE_FAIL, error: error, email_error: email_error }
  }
}
async function updateProfileAuth(dispatch, token, refresh_token, session_id, email) {
  try {
    const upd_admin_email = await authService.updateAdminEmail(token, session_id, email)
    //console.log('upd_admin_email > ', upd_admin_email)
    upd_admin_email['token'] = token
    upd_admin_email['refreshToken'] = refresh_token

    return upd_admin_email
  } catch (e) {
    console.log('updateProfileAuth error > ', e)
    if (e.toString() === 'jwt expired') {
      // refresh token and retry 1 time.
      try {
        const upd_token = await authenticationActions.refreshToken(
          dispatch,
          refresh_token,
          session_id,
        )
        if (upd_token && upd_token.error) {
          return { error: upd_token.error }
        }
        const upd_admin_email = await authService.updateAdminEmail(
          upd_token.token,
          session_id,
          email,
        )
        //console.log('upd_admin_email > ', upd_admin_email)
        upd_admin_email['token'] = upd_token.token
        upd_admin_email['refreshToken'] = upd_token.refreshToken
        return upd_admin_email
      } catch (e2) {
        console.log('updateProfileAuth error 2 > ', e2)
        return { error: e2 }
      }
    } else {
      return { error: e }
    }
  }
}
async function updateProfileApi(
  dispatch,
  token,
  refresh_token,
  session_id,
  name,
  email,
  department,
  phone,
) {
  try {
    const upd_user_profile = await userService.updateUserProfile(
      token,
      session_id,
      name,
      email,
      department,
      phone,
    )
    return upd_user_profile
  } catch (e) {
    console.log('updateProfileApi error > ', e)
    if (e.toString() === 'jwt expired') {
      try {
        const upd_token = await authenticationActions.refreshToken(
          dispatch,
          refresh_token,
          session_id,
        )
        if (upd_token && upd_token.error) {
          return { error: upd_token.error }
        }
        const upd_user_profile = await userService.updateUserProfile(
          upd_token.token,
          session_id,
          name,
          email,
          department,
          phone,
        )
        console.log('upd_user_profile > ', upd_user_profile)
        return upd_user_profile
      } catch (e2) {
        console.log('updateProfileApi error 2 > ', e2)
        return { error: e2 }
      }
    } else {
      return { error: e.toString(), message: e.toString() }
    }
  }
}

function userList(token, refresh_token, session_id, current_page, keyword, sort_by, sort_order) {
  console.log('userActions.userList')
  return async (dispatch) => {
    dispatch(utils.blockui())
    let user_list = {}
    let user_total = {}
    try {
      user_list = await userService.getUserList(
        token,
        session_id,
        current_page,
        keyword,
        sort_by,
        sort_order,
      )

      for (let i = 0; i < user_list.users.length; i++) {
        //console.log(' user > ', user_list.users[i])
        const imgUrl = await getUserProfileImg(token, user_list.users[i].avatar_filepath)
        //console.log(' imgUrl > ', imgUrl.imgUrl)
        user_list.users[i]['imgUrl'] = imgUrl.imgUrl
        //console.log(' user added > ', user_list.users[i])
      }
      console.log('user_list > ', user_list)
      user_total = user_list.userTotal
      //user_total = await userService.getUserTotal(token, session_id, current_page, keyword)
      console.log('user_total > ', user_total)
      dispatch(success(user_list, user_total))
      dispatch(utils.unblockui())
      /*console.log('user_total > ', user_total)
      dispatch(success(user_list, user_total))
      dispatch(utils.unblockui())*/
    } catch (e1) {
      console.log('getUserList error > ', e1)
      if (e1.toString() === 'jwt expired') {
        try {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          if (upd_token && upd_token.error) {
            dispatch(failure(upd_token.error))
            dispatch(utils.unblockui())
          }
          user_list = await userService.getUserList(
            upd_token.token,
            session_id,
            current_page,
            keyword,
            sort_by,
            sort_order,
          )
          console.log('user_list > ', user_list)
          user_total = user_list.userTotal
          console.log('user_total > ', user_total)
          dispatch(success(user_list, user_total))
          dispatch(utils.unblockui())
        } catch (e12) {
          console.log('updateProfileApi error 2 > ', e12)
          dispatch(failure(e12.toString()))
          dispatch(utils.unblockui())
        }
      } else {
        dispatch(failure(e1.toString()))
        dispatch(utils.unblockui())
      }
    }
  }
  function success(user_list, user_total) {
    return { type: userConstants.USER_LIST_SUCCESS, userList: user_list, userTotal: user_total }
  }
  function failure(error) {
    return { type: userConstants.USER_LIST_FAIL, error: error }
  }
}

function getUserLogs(token, refresh_token, session_id, user_id, current_page, sort_by, sort_order) {
  console.log('userActions.getUserLogs')
  return async (dispatch) => {
    dispatch(utils.blockui())
    let userLogList = {}
    let userLogTotal = {}
    try {
      userLogList = await userService.getUserLogList(
        token,
        session_id,
        user_id,
        current_page,
        sort_by,
        sort_order,
      )
      console.log('userLogList > ', userLogList)
      userLogTotal = userLogList.userLogTotal
      dispatch(success(userLogList, userLogTotal))
      dispatch(utils.unblockui())
    } catch (e1) {
      console.log('getUserLogs error > ', e1)
      if (e1.toString() === 'jwt expired') {
        try {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          if (upd_token && upd_token.error) {
            dispatch(failure(upd_token.error))
            dispatch(utils.unblockui())
          } else {
            userLogList = await userService.getUserLogList(
              upd_token.token,
              session_id,
              user_id,
              current_page,
              sort_by,
              sort_order,
            )
            console.log('userLogList > ', userLogList)
            userLogTotal = userLogList.userLogTotal
            dispatch(success(userLogList, userLogTotal))
            dispatch(utils.unblockui())
          }
        } catch (e2) {
          dispatch(failure(e1.toString()))
          dispatch(utils.unblockui())
        }
      } else {
        dispatch(failure(e1.toString()))
        dispatch(utils.unblockui())
      }
    }
  }
  function success(userLogList, userLogTotal) {
    return {
      type: userConstants.USERLOG_LIST_SUCCESS,
      userLogList: userLogList,
      userLogTotal: userLogTotal,
    }
  }
  function failure(error) {
    return { type: userConstants.USERLOG_LIST_FAIL, error: error }
  }
}
function getUserDetail(token, refresh_token, session_id, user_id) {
  return async (dispatch) => {
    try {
      dispatch(utils.blockui())
      console.log('getUserDetail > ', user_id)
      const get_user_profile = await userService.getUserDetail(token, user_id, session_id)
      //console.log('get_user_profile > ', get_user_profile)
      let userDetail = get_user_profile.data.users_by_pk
      const get_user_privilege = await authService.getPrivilege(token, user_id)
      //console.log('get_user_privilege > ', get_user_privilege)
      userDetail['roles'] = get_user_privilege.data.authentication_users_by_pk.roles
      dispatch(success(userDetail))
      dispatch(utils.unblockui())
    } catch (e) {
      console.log('getUserDetail error > ', e)
      if (e.toString() === 'jwt expired') {
        const upd_token = await authenticationActions.refreshToken(
          dispatch,
          refresh_token,
          session_id,
        )
        if (upd_token && upd_token.error) {
          dispatch(failure(upd_token.error))
          dispatch(utils.unblockui())
        } else {
          const get_user_profile = await userService.getUserDetail(
            upd_token.token,
            user_id,
            session_id,
          )
          //console.log('get_user_profile > ', get_user_profile)
          let userDetail = get_user_profile.data.users_by_pk
          const get_user_privilege = await authService.getPrivilege(upd_token.token, user_id)
          //console.log('get_user_privilege > ', get_user_privilege)
          userDetail['roles'] = get_user_privilege.data.authentication_users_by_pk.roles
          dispatch(success(userDetail))
          dispatch(utils.unblockui())
        }
      } else {
        if (e.toString() === 'Page Not Found.') {
          dispatch(failure(i18n.t('user_not_found')))
          dispatch(utils.unblockui())
        } else {
          dispatch(failure(e.toString()))
          dispatch(utils.unblockui())
        }
      }
    }
  }
  function success(userDetail) {
    return { type: userConstants.GET_USER_DETAIL_SUCCESS, userDetail: userDetail }
  }
  function failure(error) {
    return { type: userConstants.GET_USER_DETAIL_FAIL, error: error }
  }
}
function changeStatus(
  token,
  refresh_token,
  session_id,
  user_id,
  current_status,
  creator_name,
  email,
  user_name,
) {
  console.log('userActions.changeStatus')
  return async (dispatch) => {
    dispatch(utils.blockui())
    if (current_status === 'inactive') {
      // send mail
      try {
        const auth = await authService.resendActivation(
          token,
          session_id,
          user_id,
          email,
          user_name,
        )
        console.log('auth > ', auth)
        // log to db
        const log_other = await userService.insertOtherLog(
          token,
          session_id,
          user_id,
          creator_name,
          'Resend Invitation',
        )
        console.log('log_other > ', log_other)
        dispatch(resendSuccess())
        dispatch(utils.unblockui())
      } catch (e1) {
        if (e1.toString() === 'jwt expired') {
          try {
            const upd_token = await authenticationActions.refreshToken(
              dispatch,
              refresh_token,
              session_id,
            )
            if (upd_token && upd_token.error) {
              dispatch(failure(upd_token.error))
              dispatch(utils.unblockui())
            } else {
              const auth = await authService.resendActivation(
                upd_token.token,
                session_id,
                user_id,
                email,
                user_name,
              )
              console.log('auth > ', auth)
              // log to db
              const log_other = await userService.insertOtherLog(
                upd_token.token,
                session_id,
                user_id,
                creator_name,
                'Resend Invitation',
              )
              console.log('log_other > ', log_other)
              dispatch(resendSuccess())
              dispatch(utils.unblockui())
            }
          } catch (e2) {
            dispatch(failure(e2.toString()))
            dispatch(utils.unblockui())
          }
        } else {
          dispatch(failure(e1.toString()))
          dispatch(utils.unblockui())
        }
      }
    } else {
      try {
        // change status
        const user = await userService.changeStatus(
          token,
          session_id,
          user_id,
          current_status,
          creator_name,
        )
        console.log('user > ', user)
        dispatch(success())
        dispatch(utils.unblockui())
      } catch (e3) {
        if (e3.toString() === 'jwt expired') {
          try {
            const upd_token = await authenticationActions.refreshToken(
              dispatch,
              refresh_token,
              session_id,
            )
            if (upd_token && upd_token.error) {
              dispatch(failure(upd_token.error))
              dispatch(utils.unblockui())
            } else {
              // change status
              const user = await userService.changeStatus(
                upd_token.token,
                session_id,
                user_id,
                current_status,
                creator_name,
              )
              console.log('user > ', user)
              dispatch(success())
              dispatch(utils.unblockui())
            }
          } catch (e4) {
            dispatch(failure(e4.toString()))
            dispatch(utils.unblockui())
          }
        } else {
          dispatch(failure(e3.toString()))
          dispatch(utils.unblockui())
        }
      }
    }
  }
  function resendSuccess() {
    return { type: userConstants.RESEND_ACTIVATION_EMAIL_SUCCESS }
  }
  function success() {
    return { type: userConstants.USER_CHANGE_STATUS_SUCCESS }
  }
  function failure(error) {
    return { type: userConstants.USER_CHANGE_STATUS_FAIL, error: error }
  }
}
function resetPassword(
  token,
  refresh_token,
  session_id,
  user_id,
  creator_name,
  email,
  contact_name,
) {
  console.log('userActions.resetPassword')
  return async (dispatch) => {
    dispatch(utils.blockui())
    try {
      const auth = await authService.resetPassword(token, session_id, user_id, email, contact_name)
      console.log('auth > ', auth)
      // log to db
      const userLog = await userService.insertOtherLog(
        token,
        session_id,
        user_id,
        creator_name,
        'Admin Reset Password',
      )
      console.log('userLog > ', userLog)
      dispatch(success())
      dispatch(utils.unblockui())
    } catch (e1) {
      console.log('e1 > ', e1)
      // refresh token
      if (e1.toString() === 'jwt expired') {
        try {
          const upd_token = await authenticationActions.refreshToken(
            dispatch,
            refresh_token,
            session_id,
          )
          if (upd_token && upd_token.error) {
            dispatch(failure(upd_token.error))
            dispatch(utils.unblockui())
          }
          const auth = await authService.resetPassword(
            upd_token.token,
            session_id,
            user_id,
            email,
            contact_name,
          )
          console.log('auth > ', auth)
          // log to db
          const userLog = await userService.insertOtherLog(
            upd_token.token,
            session_id,
            user_id,
            creator_name,
            'Admin Reset Password',
          )
          console.log('userLog > ', userLog)
          dispatch(success())
          dispatch(utils.unblockui())
        } catch (e2) {
          dispatch(failure(e2.toString()))
          dispatch(utils.unblockui())
        }
      } else {
        dispatch(failure(e1.toString()))
        dispatch(utils.unblockui())
      }
    }
  }
  function success() {
    return { type: userConstants.USER_CHANGE_STATUS_SUCCESS }
  }
  function failure(error) {
    return { type: userConstants.USER_CHANGE_STATUS_FAIL, error: error }
  }
}
async function getUserProfileImg(token, avatar_filepath) {
  if (!avatar_filepath) {
    return ''
  }
  return userService.getPresignedURL(token, avatar_filepath).then(
    (url) => {
      return url
    },
    (error) => {
      console.log('Error > ', error)
      return ''
    },
  )
  /*minioActions.viewPublicFile(token, avatar_filepath).then(
    (url) => {
      console.log('url > ', url.imgUrl)
      return <CAvatar src={url.imgUrl} size="lg" />
    },
    (error) => {
      console.log('error > ', error.toString())
      return <CIcon icon={cilUser} />
    },
  )*/
}
