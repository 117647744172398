import { alertConstants } from '../constants'
export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SHOW_ALERT:
      return {
        alert: action.alert,
        alerttype: action.alerttype,
      }
    case alertConstants.CLEAR_ALERT:
      return {
        alert: '',
        alerttype: '',
      }
    default:
      return state
  }
}
