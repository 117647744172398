export function authHeader(token) {
  return {
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Origin': '*',
    token: token,
  }
}
export function authHeaderBearer(token) {
  return {
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer ' + token,
  }
}
export function authHeaderFile(token) {
  return {
    'Access-Control-Allow-Headers': 'Authorization',
    'Access-Control-Allow-Origin': '*',
    token: token,
  }
}
export function noAuthHeader() {
  return {
    'Content-Type': 'application/json',
  }
}
