let sidebarShow = {}
const initialState = sidebarShow ? { sidebarShow: true, lng: 'en' } : {}

export function menu(state = initialState, action) {
  switch (action.type) {
    case 'set':
      return {
        sidebarShow: action.sidebarShow,
      }
    case 'lng':
      return {
        lng: action.lng,
      }
    default:
      return state
  }
}
