import { blockuiConstants, alertConstants } from '../constants'
export const utils = {
  blockui,
  unblockui,
  showAlert,
  clearAlert,
}
function blockui() {
  return { type: blockuiConstants.BLOCKUI_LOADING }
}
function unblockui() {
  return { type: blockuiConstants.BLOCKUI_DONE }
}
function showAlert(alert, alerttype) {
  return { type: alertConstants.SHOW_ALERT, alert: alert, alerttype: alerttype }
}
function clearAlert() {
  return { type: alertConstants.CLEAR_ALERT }
}
