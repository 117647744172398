export const userConstants = {
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAIL: 'ADD_USER_FAIL',
  ADD_USER_CLEAR_ERROR: 'ADD_USER_CLEAR_ERROR',

  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',
  UPDATE_USER_CLEAR_ERROR: 'UPDATE_USER_CLEAR_ERROR',

  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAIL: 'UPDATE_PROFILE_FAIL',
  USER_PROFILE_CLEAR_ERROR: 'USER_PROFILE_CLEAR_ERROR',

  USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
  USER_LIST_FAIL: 'USER_LIST_FAIL',
  USER_LIST_CLEAR_ERROR: 'USER_LIST_CLEAR_ERROR',

  GET_USER_DETAIL_SUCCESS: 'GET_USER_DETAIL_SUCCESS',
  GET_USER_DETAIL_FAIL: 'GET_USER_DETAIL_FAIL',
  GET_USER_DETAIL_CLEAR_ERROR: 'GET_USER_DETAIL_CLEAR_ERROR',

  USERLOG_LIST_SUCCESS: 'USERLOG_LIST_SUCCESS',
  USERLOG_LIST_FAIL: 'USERLOG_LIST_FAIL',
  USERLOG_LIST_CLEAR_ERROR: 'USERLOG_LIST_CLEAR_ERROR',

  USER_CHANGE_STATUS_SUCCESS: 'USER_CHANGE_STATUS_SUCCESS',
  USER_CHANGE_STATUS_FAIL: 'USER_CHANGE_STATUS_FAIL',
  RESEND_ACTIVATION_EMAIL_SUCCESS: 'RESEND_ACTIVATION_EMAIL_SUCCESS',
}
