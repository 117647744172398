import { uploadConstants } from '../constants'
export function upload(state = {}, action) {
  switch (action.type) {
    case uploadConstants.UPLOAD_FILE_SUCCESS:
      return {
        error: '',
      }
    case uploadConstants.UPLOAD_FILE_ERROR:
      return {
        error: action.error,
      }
    case uploadConstants.UPLOAD_FILE_CLEAR_ERROR:
      return {
        error: '',
      }
    case uploadConstants.DELETE_AVATAR_FILE_SUCCESS:
      return {
        error: '',
      }
    case uploadConstants.DELETE_FILE_ERROR:
      return {
        error: action.error,
      }

    default:
      return state
  }
}
