import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/style.scss'
import './i18n'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const PublicLayout = React.lazy(() => import('./layout/PublicLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Verify = React.lazy(() => import('./views/pages/verify/Verify'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            <Route
              path="/verify"
              name="User Activation"
              render={(props) => <Verify {...props} />}
            />
            <Route
              path="/forgotpassword"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route
              path="/dashboard"
              name="Dashboard"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              path="/adminprofile"
              name="Profile"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              path="/usermgt"
              name="User Management"
              render={(props) => <DefaultLayout {...props} />}
            />
            <Route
              exact
              path="/"
              name="Public Page"
              render={(props) => <PublicLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    )
  }
}

export default App
